<template>
  <tr>
    <td>
      <div class="flex justify-between w-full">
        <table class="platform-table w-1/2 bg-white mr-auto">
          <thead>
            <tr>
              <th class="bg-white border-b-2 border-black">Platform</th>
              <th class="bg-white border-b-2 border-black">Video Link</th>
              <th class="bg-white border-b-2 border-black">
                <button @click="addNewPlatformRow" style="background-color: #86bc25; color: white" class="hover:bg-green-700 text-white py-1 px-2 rounded">Add</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in localPlatformData" :key="index">
              <td class="p-2">
                <span>{{ row.platform }}</span>
              </td>
              <td class="p-2">
                <a :href="row.videoLink">{{ row.videoLink }}</a>
              </td>
              <td class="p-2">
                <button @click="makeInactive(row.id)" class="bg-red-400 hover:bg-red-600 text-white py-1 px-2 rounded">&#10005;</button>
              </td>
            </tr>
            <tr v-if="isNewRow">
              <td class="p-2">
                <select v-model="newRow.platform" v-if="isNewRow" style="border: 1px solid #d3d3d3; border-radius: 0.25rem">
                  <option value="Instagram">Instagram</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Youtube">Youtube</option>
                  <option value="TikTok">TikTok</option>
                  <option value="SnapChat">SnapChat</option>
                  <option value="Twitter">Twitter</option>
                  <option value="Other">Other</option>
                </select>
              </td>
              <td class="p-2">
                <input v-model="newRow.videoLink" type="text" v-if="isNewRow" style="border: 1px solid #d3d3d3; border-radius: 0.25rem" />
              </td>
              <td class="p-2">
                <button v-if="isNewRow" @click="confirmNewPlatform()" class="bg-green-400 hover:bg-green-600 text-white py-1 px-2 rounded">&#x2713;</button>
                <button @click="removePlatformRow()" class="bg-red-400 hover:bg-red-600 text-white py-1 px-2 rounded ml-2">&#10005;</button>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="extra-table w-1/2 bg-white ml-auto">
          <thead>
            <tr>
              <th class="bg-white border-b-2 border-black">Comp ID</th>
              <th class="bg-white border-b-2 border-black">Comp Title</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="comp in extraData" :key="comp.id" class="bg-green-50">
              <td class="p-2">{{ comp.id?comp.id:'' }}</td>
              <td class="p-2">{{ comp.composition?.title || comp.newtitle || '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from "axios";
export default {
  props: {
    extraData: {
      type: Object,
      required: false,
    },
    platformData: {
      type: Object,
      required: false,
    },
    videoId: {
      type: Number,
    },
  },
  data() {
    return {
      parsedPlatformData: [],
      localPlatformData: this.platformData ? this.platformData : [],
      isNewRow: false,
      newRow:{        
        platform: "",
        videoLink: "",
      },
      videoId: this.videoId? this.videoId: 0,
    };
  },
  watch: {
    platformData(newVal) {
      this.localPlatformData = newVal;
    },
  },
  methods: {
    makeInactive(platformId) {
      console.log(this.localPlatformData);
      if (confirm("Are you sure you want to deactivate this platform?")) {
        axios
          .post("/video/lisynce/deactivate-platform/" + platformId)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
        this.localPlatformData = this.localPlatformData.filter(
          (platform) => platform.id !== platformId
        );
      }
    },
    addNewPlatformRow() {
      this.isNewRow = true;
      this.newRow = {
        platform: "",
        videoLink: "",
      };
    },
    confirmNewPlatform() {
      console.log(this.videoId);
      this.localPlatformData.push(this.newRow);
      this.isNewRow = false;
      axios
          .post("/video/lisynce/add-platform/" + this.videoId, this.newRow)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      this.newRow = {
        platform: "",
        videoLink: "",
      };
    },
    removePlatformRow(){
      this.isNewRow = false;
      this.newRow = {
        platform: "",
        videoLink: "",
      };
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  justify-content: space-between; /* This will space out the child elements */
}

.platform-table,
.extra-table {
  width: calc(50% - 1rem); /* Adjust width to account for margin */
  table-layout: fixed;
}

/* If the tables still don't fit, you can try reducing the width a bit more */
.platform-table,
.extra-table {
  width: calc(48% - 1rem);
}

/* Ensure the tables do not exceed their half of the container */
table {
  max-width: 100%;
}

.platform-table thead th,
.platform-table tbody td,
.extra-table thead th,
.extra-table tbody td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.platform-table tr,
.extra-table tr {
  border-bottom: 1px solid #cccccc;
}

/* Example fixed widths for each column */
.extra-table thead th:nth-child(1),
.extra-table tbody td:nth-child(1) {
  width: 30%; /* Width of the first column */
}

.extra-table thead th:nth-child(2),
.extra-table tbody td:nth-child(2) {
  width: 65%; /* Width of the second column */
}

.platform-table thead th:nth-child(1),
.platform-table tbody td:nth-child(1) {
  width: 20%; /* Keep the width for the first column */
}

.platform-table thead th:nth-child(2),
.platform-table tbody td:nth-child(2) {
  width: 60%; /* Decrease the width for the video link column */
}

.platform-table thead th:nth-child(3),
.platform-table tbody td:nth-child(3) {
  width: 25%; /* Increase the width for the action column */
}
</style>
