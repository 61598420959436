<template>
    <div class="relative bg-white p-6 rounded-lg shadow-lg w-full md:max-w-5xl">
      <h2 class="text-xl font-bold text-gray-900 mb-4">Video Information</h2>
      
        <div class="space-y-8">
          <div class="p-4 bg-white shadow-md rounded-md border-2 border-green-100 relative mt-6">
            <div class="absolute top-0 left-0 right-0 bg-green-100 px-2 py-0 mt-6t">
              <h4 class="text-green-700 font-bold text-sm mb-4 text-left">Video Information</h4>
            </div>
            <div class="grid grid-cols-2 gap-4 mt-12">
              <label for="title" class="text-gray-700 text-sm font-bold flex items-center">Title</label>
              <input v-model="form.title" id="title" class="text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Title"/>
              <!-- Dropdown -->
              
              <!-- Text Area 1 -->
              <label for="description" class="text-gray-700 text-sm font-bold flex items-center">Description</label>
              <textarea v-model="form.description" id="description" class="text-xs shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Enter description"></textarea>
              <!-- <label for="link" class="text-gray-700 text-sm font-bold flex items-center">Program Link</label>
              <input v-model="form.url" id="link" class=" text-xs shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="i.e, https://www.your.link"/>
              
              <div class="col-span-2">
                <div class="grid grid-cols-2 gap-4 items-start">

                  <label for="eventNotes" class="text-gray-700 text-sm font-bold col-span-1 text-left">Tags</label>
                  <div class="col-span-1">
                    <p class="text-gray-500 text-xs text-left">
                      Add tags to help subscribers find your video. Separate tags with commas.
                    </p>
                    <p class="text-gray-700 text-xs text-left mt-2">
                      Do NOT use tags that aren't directly related to the video or are too generic. Doing so can get your video rejected and cause delays in your upload.
                    </p>
                    <textarea v-model="form.tags" id="eventNotes" class="text-xs shadow border rounded w-full py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-2" placeholder="Tag1, Tag2, Tag3..."></textarea>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 gap-4 mt-6">
              <table class="platform-table w-full bg-white mr-auto">
                <thead>
                  <tr>
                    <th class="bg-white border-b-2 border-black">Platform</th>
                    <th class="bg-white border-b-2 border-black">Video Link</th>
                    <th class="bg-white border-b-2 border-black">
                      <button @click="addNewPlatformRow" style="background-color: #86bc25; color: white" class="hover:bg-green-700 text-white py-1 px-2 rounded">Add</button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row, index) in form.platformRows" :key="index">
                    <td class="p-2">
                      <span>{{ row.platform }}</span>
                    </td>
                    <td class="p-2">
                      <a :href="row.videoLink">{{ row.videoLink }}</a>
                    </td>
                    <td class="p-2">
                      <button @click="removePlatformRow(index)" class="bg-red-400 hover:bg-red-600 text-white py-1 px-2 rounded">&#10005;</button>
                    </td>
                  </tr>
                  <tr v-if="isNewRow">
                    <td class="p-2">
                      <select v-model="newRow.platform" v-if="isNewRow" style="border: 1px solid #d3d3d3; border-radius: 0.25rem">
                        <option value="Instagram">Instagram</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Youtube">Youtube</option>
                        <option value="TikTok">TikTok</option>
                        <option value="SnapChat">SnapChat</option>
                        <option value="Twitter">Twitter</option>
                        <option value="Other">Other</option>
                      </select>
                    </td>
                    <td class="p-2">
                      <input v-model="newRow.videoLink" type="text" v-if="isNewRow" style="border: 1px solid #d3d3d3; border-radius: 0.25rem" />
                    </td>
                    <td class="p-2">
                      <button v-if="isNewRow" @click="confirmNewPlatform()" class="bg-green-400 hover:bg-green-600 text-white py-1 px-2 rounded">Save</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="p-4 bg-red-100 shadow-md rounded-md border-2 border-red-100 relative mt-6" v-if="form.videoTitle == ''">
              <p class="text-red-700 mb-1 text-xs text-left">
                * Title is required.
                  </p>
            </div>


        </div>
      
        <div class="mt-8 flex justify-between">
            <button class="bg-gray-200 text-gray-800 rounded-lg px-5 py-2 hover:bg-gray-300" @click="emitClose()">Cancel</button>
            <div class="flex items-center space-x-3">
                <button class="bg-red-800 text-white rounded-lg px-5 py-2 hover:bg-red-900" @click="emitPrevious()">Back</button>
                <button @click="emitComplete()" class="bg-red-500 text-white rounded-lg px-5 py-2 hover:bg-red-600">Complete</button>
            </div>
        </div>
    </div>
  </template>

  <script setup>
  import { defineEmits, onMounted, ref, reactive } from 'vue';
  import axios from 'axios';

  const emit = defineEmits(['next', 'close', 'previous']);

  const form = ref({
    title: '',
    description: '',
    url: '',
    platformRows: [],
  });

//   const requirementTypes = ref([]);
//   const showError = ref(false);

//   onMounted(async () => {
//     try {
//       const response = await axios.get('/requirement-types');
//       requirementTypes.value = response.data;
//     } catch (error) {
//       console.error('Error fetching requirement types:', error);
//     }
//   });

const emitComplete = () => {
  if(form.value.videoTitle == '') {
    return
  }
    emit('complete', {
        data: form.value
  });
}

  const emitClose = () => {
    emit('close');
  };
  
  const emitPrevious = () => {
    emit('previous');
  };

const isNewRow = ref(false); // Ref to indicate if a new row is being added
const newRow = ref({ // Ref to hold data of new row
  platform: '',
  videoLink: '' // Flag to indicate it's a new row
});

const addNewPlatformRow = () => {

  isNewRow.value = true; // Set flag to indicate new row
  newRow.value = {
    platform: '',
    videoLink: '',// Set isNew flag for new row
  };
  console.log('addNew is called '+ isNewRow.value)
};

const confirmNewPlatform = () => {
  form.value.platformRows.push(newRow.value); // Add new row to array
  isNewRow.value = false; // Reset flag
};

const removePlatformRow = (index) => {
  form.value.platformRows.splice(index, 1); // Remove row from array
};
  </script>

  <style>
  /* Additional custom styling if needed */
  </style>
